import { combineReducers } from "redux";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import globalDataReducer from "./globalDataSlice";
const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  globalData: globalDataReducer,
});

export default rootReducer;
