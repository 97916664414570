import DiscountCalculator from "@/lib/calculations/discountCalculator";
import { SellType } from "@/types";
import { Product } from "@/types/dtos/products";
import { useMemo } from "react";

const useDiscountPrice = (
  product: Product,
  quantity: number,
  sellType?: SellType
) => {
  const discountPrice = useMemo(() => {
    // const { price, campaignItems } = product || {};
    // const { quantityComparingToDefaultSellType, type } = sellType || {};

    // const ItemTotalPrice =
    //   quantity * (price * (quantityComparingToDefaultSellType || 1));

    const { totalDiscount, hasDiscount } = DiscountCalculator(
      product,
      quantity,
      sellType
    );
    return { discountPrice: hasDiscount ? totalDiscount : 0, hasDiscount };
  }, [product, quantity, sellType]);

  return { discountPrice };
};

export default useDiscountPrice;
