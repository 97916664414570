import CategoriesMobile from "@/components/categories-mobile";
import React from "react";
import { links } from "@/data";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "@/app/i18n/client";
import useSession from "@/hooks/useSession";
import { ROUTES } from "@/utils/routes";
import useUI from "@/hooks/useUI";
import { Button } from "antd";
import { FaUserLarge } from "react-icons/fa6";
import { GoSync } from "react-icons/go";

const MobileSideBarContent = ({ categories }) => {
  const { lng } = useParams();
  const { t: tFooter } = useTranslation(lng, "footer");
  const { t } = useTranslation(lng, "common");
  const { isAuthorized } = useSession();
  const { openModal, setModalView, closeSidebar } = useUI();
  const router = useRouter();
  const handleLogin = () => {
    setModalView("LOGIN_VIEW");
    return openModal();
  };
  const handleNavigateAccount = () => {
    closeSidebar();
    if (!isAuthorized) {
      handleLogin();

      return;
    }
    router.push(`/${lng}${ROUTES.ACCOUNT}`);
  };
  const handleNavigateRecovery = () => {
    closeSidebar();
    if (!isAuthorized) {
      handleLogin();

      return;
    }
    router.push(`/${lng}${ROUTES.ACCOUNT_ORDERS}`);
  };
  return (
    <div className="flex flex-col gap-10">
      <div className="-mt-0.5 items-start justify-start flex gap-4 flex-col">
        <Button type="text" onClick={handleNavigateAccount} className=" p-0">
          <div className="flex items-center text-black gap-3">
            <FaUserLarge className="text-primaryDark" size={25} />

            <span className="text-primary font-bold text-base  whitespace-nowrap">
              {t("text-account")}
            </span>
          </div>
        </Button>

        <Button type="text" onClick={handleNavigateRecovery} className=" p-0">
          <div className="flex flex-row items-center gap-2">
            <GoSync className="text-primaryDark" size={20} />
            <span className="text-primary font-bold text-base  whitespace-nowrap">
              {t("text-order-recovery")}
            </span>
          </div>
        </Button>
      </div>
      <CategoriesMobile lng={lng} categories={categories} />
      <div className="flex flex-col gap-5">
        {links?.map((link, index) => (
          <Link
            key={`${index}-${link?.id}`}
            href={`/${lng}${link?.href}`}
            onClick={closeSidebar}
            className="flex items-center gap-4"
          >
            <div className="border-[1px] border-paleYellow w-4 h-4 rounded-full"></div>
            <span className="text-base text-primary font-semibold">
              {tFooter(link?.text)}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MobileSideBarContent;
