import { CART_ACTION_TYPES } from "@/constants/actionTypes";
import { storeData } from "@/utils/storage";
import { CalculateItemsTotal } from "../calculations/totalCalculator";
import DiscountCalculator from "../calculations/discountCalculator";

const {
  ADD_ITEM_WITH_QUANTITY,
  REMOVE_ITEM_OR_QUANTITY,
  REMOVE_ITEM,
  CLEAR_CART,
  SAVE_NOTE,
  UPDATE_SELL_TYPE,
  FETCH_CART_V3,
} = CART_ACTION_TYPES;

const initialState = {
  items: [],
  total: 0,
  totalPrices: {
    total: 0,
    totalBeforeDiscount: 0,
    totalDiscounts: 0,
  },
  isLoading: false,
  error: {},
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_WITH_QUANTITY: {
      const { item, quantity } = action.payload;
      const { totalDiscount, campaignDiscount, hasDiscount } =
        DiscountCalculator(item, quantity, item.sellType);
      // const itemPrice =
      //   quantity *
      //   item.price *
      //   (item.sellType?.quantityComparingToDefaultSellType || 1);
      const foundItemIndex = state.items.findIndex(
        (product) => item.id === product.id
      );
      let updatedItems;
      if (foundItemIndex > -1) {
        updatedItems = state.items.map((cartItem) =>
          cartItem.id === item.id
            ? {
                ...cartItem,
                quantity: parseFloat((cartItem.quantity + quantity).toFixed(3)),
                discountPrice: hasDiscount ? totalDiscount : undefined,
                campaignDiscount,
              }
            : {
                ...cartItem,
                discountPrice: hasDiscount ? totalDiscount : undefined,
                campaignDiscount,
              }
        );
      } else {
        updatedItems = [
          ...state.items,
          {
            ...item,
            quantity: parseFloat(quantity.toFixed(3)),
            discountPrice: hasDiscount ? totalDiscount : undefined,
            campaignDiscount,
          },
        ];
      }
      const totalPrices = CalculateItemsTotal(updatedItems);
      storeData("cart_v3", {
        cart: {
          items: updatedItems,
          totalPrices,
        },
      });
      return {
        ...state,
        items: updatedItems,
        totalPrices,
      };
    }
    case REMOVE_ITEM_OR_QUANTITY: {
      const { id: _id, quantity: _quantity } = action.payload;
      const foundItemIndex = state.items.findIndex(
        (product) => _id === product.id
      );
      if (foundItemIndex === -1) return state; // Item not found
      const updatedItems = state.items
        .map((cartItem, index) => {
          if (index === foundItemIndex) {
            const newQuantity = cartItem.quantity - _quantity;
            return newQuantity > 0
              ? { ...cartItem, quantity: parseFloat(newQuantity.toFixed(3)) }
              : null; // Mark for removal
          }
          const { totalDiscount, campaignDiscount, hasDiscount } =
            DiscountCalculator(cartItem, _quantity, cartItem.sellType);
          return {
            ...cartItem,
            discountPrice: hasDiscount ? totalDiscount : undefined,
            campaignDiscount,
          };
        })
        .filter(Boolean); // Remove null entries
      const totalPrices = CalculateItemsTotal(updatedItems);
      storeData("cart_v3", {
        cart: { items: updatedItems, totalPrices },
      });

      return {
        ...state,
        items: updatedItems,
        totalPrices,
      };
    }
    case UPDATE_SELL_TYPE: {
      const { id, sellType, quantity } = action.payload;
      const updatedItems = state.items.map((cartItem) =>
        cartItem.id === id ? { ...cartItem, sellType, quantity } : cartItem
      );
      const totalPrices = CalculateItemsTotal(updatedItems);
      storeData("cart_v3", {
        cart: { items: updatedItems, totalPrices },
      });
      return {
        ...state,
        items: updatedItems,
        totalPrices,
      };
    }

    case REMOVE_ITEM: {
      const { id } = action.payload;
      const updatedItems = state.items.filter((cartItem) => cartItem.id !== id);
      const totalPrices = CalculateItemsTotal(updatedItems);
      storeData("cart_v3", {
        cart: { items: updatedItems, totalPrices },
      });
      return { ...state, items: updatedItems, totalPrices };
    }
    case CLEAR_CART: {
      storeData("cart_v3", {
        cart: {
          items: [],
          totalPrices: {
            total: 0,
            totalBeforeDiscount: 0,
            totalDiscounts: 0,
          },
        },
      });
      return {
        ...state,
        items: [],
        totalPrices: {
          total: 0,
          totalBeforeDiscount: 0,
          totalDiscounts: 0,
        },
      };
    }
    case SAVE_NOTE: {
      const { id, note } = action.payload;
      const updatedItems = state.items.map((cartItem) =>
        cartItem.id === id ? { ...cartItem, note } : cartItem
      );
      const totalPrices = CalculateItemsTotal(updatedItems);
      storeData("cart_v3", {
        cart: { items: updatedItems, totalPrices },
      });
      return {
        ...state,
        items: updatedItems,
        totalPrices,
      };
    }
    case FETCH_CART_V3: {
      const { cart: { items = [], total = 0 } = {} } = action.payload;
      const totalPrices = CalculateItemsTotal(items);
      return { ...state, items, totalPrices };
    }
    default:
      return state;
  }
};

export default cartReducer;
