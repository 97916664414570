// globalDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const globalDataSlice = createSlice({
  name: "globalData",
  initialState: {
    data: null,
    categories: null,
  },
  reducers: {
    setGlobalData: (state, action) => {
      state.data = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { setGlobalData, setCategories } = globalDataSlice.actions;
export default globalDataSlice.reducer;
