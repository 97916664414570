import React from "react";
import Link from "next/link";
import { Menu } from "antd";
import { ROUTES } from "@/utils/routes";
import useUI from "@/hooks/useUI";
import { RiPokerDiamondsFill } from "react-icons/ri";
const { SubMenu } = Menu;

const CategoriesMobile = ({ className, lng, categories }) => {
  const { closeSidebar } = useUI();

  return (
    <Menu
      mode="inline"
      className={className}
      style={{ width: "100%", border: "unset" }}
    >
      {categories?.map((item, index) => (
        <React.Fragment key={item?.id}>
          {item?.subCategories && item?.subCategories?.length ? (
            <SubMenu
              key={item?.id}
              title={
                <div className="flex items-center gap-1">
                  <RiPokerDiamondsFill
                    className="text-primaryLight"
                    size={14}
                  />
                  <Link
                    href={`/${lng}/${ROUTES.STORE}/${item?.id}/${item?.name}`}
                    className="inline-flex items-center text-xl font-bold text-heading hover:text-black focus:outline-none"
                  >
                    {item?.name}
                  </Link>
                </div>
              }
            >
              {item.subCategories.map((subItem) => (
                <Menu.Item key={subItem?.id} onClick={closeSidebar}>
                  <Link
                    className="text-lg hover:text-black focus:outline-none"
                    href={`/${lng}/${ROUTES.STORE}/${subItem?.id}/${subItem?.name}`}
                  >
                    {subItem?.name}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item?.id} onClick={closeSidebar}>
              <div className="flex items-center gap-1">
                <RiPokerDiamondsFill className="text-primaryLight" size={14} />
                <Link
                  href={`/${lng}/${ROUTES.STORE}/${item?.id}/${item?.name}`}
                  className="inline-flex items-center text-lg font-bold text-heading hover:text-black"
                >
                  {item?.name}
                </Link>
              </div>
            </Menu.Item>
          )}
        </React.Fragment>
      ))}
    </Menu>
  );
};

export default CategoriesMobile;
