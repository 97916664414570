export const ROUTES = {
  HOME: "/",
  PRODUCTS: `/products`,
  PRODUCT: `/product`,
  SUPPORT: "/support",
  PAYMENT: "/payment",

  CHECKOUT: "/checkout",
  CONTACT: "/contact-us",
  ABOUT: "/about-us",
  STORE: "/store",
  CUSTOMER_SERVICE: "/customer-service",
  SHIPMENTS: "/shipments",

  SHARE_EXPERIENCE: "/share-experience",
  FAQ: "/faq",

  TERMS: "/terms",

  ACCOUNT: "/my-account",
  ORDERS: "/my-account/orders",
  ACCOUNT_DETAILS: "/my-account/account-details",
  CHANGE_PASSWORD: "/my-account/change-password",

  ACCOUNT_ORDERS: "/my-account/orders",
};
