"use client";
import useFetch from "@/hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import CartActions from "@/actions/cart";
import ProductsActions from "@/actions/products";
import { useEffect } from "react";
import { Product, SellType } from "@/types/dtos/products";
import DiscountCalculator from "@/lib/calculations/discountCalculator";
import { message } from "antd";
import _ from "lodash";

const useCartFetch = (isVisible: boolean) => {
  const { loading, error, fetchData } = useFetch();
  const dispatch = useDispatch();
  const { items = [] } = useSelector(
    (state: { cart: { items: Product[] } }) => state.cart
  );
  const cartActions = CartActions();
  const productsActions = ProductsActions();

  const fetchCartItems = async () => {
    const Ids = items ? items.map((item: Product) => item.id) : [];
    if (Ids?.length) {
      const response = await fetchData(() =>
        productsActions.getProducts({ productsIds: Ids })
      );
      const productsItems: Product[] = response?.data?.products;
      // .map(
      //   (item: Product) =>
      //     item.id === 421
      //       ? {
      //           ...item,
      //           campaignItems: [],
      //         }
      //       : item
      // );
      const updatedCart: Product[] = [];
      let hasChanges = false;
      items.forEach((cartItem: Product) => {
        const fetchedItem = productsItems.find(
          (item: Product) => item.id === cartItem.id
        );
        if (
          fetchedItem &&
          (cartItem?.sellType?.type === fetchedItem.defaultSellType.type ||
            fetchedItem?.additionalSellTypes?.some(
              (additionalType: SellType) =>
                cartItem?.sellType?.type === additionalType.type
            ))
        ) {
          const { totalDiscount, campaignDiscount, hasDiscount } =
            DiscountCalculator(
              fetchedItem,
              cartItem.quantity!,
              cartItem.sellType
            );
          if (
            !_.isEqual(cartItem.defaultSellType, fetchedItem.defaultSellType) ||
            cartItem.price !== fetchedItem.price ||
            cartItem.oldPrice !== fetchedItem.oldPrice ||
            !_.isEqual(cartItem.campaignItems, fetchedItem.campaignItems) 
            // ||
            // !_.isEqual(cartItem.campaignDiscount, campaignDiscount)
          ) {
            hasChanges = true;
          }
          updatedCart.push({
            ...cartItem,
            additionalSellTypes: fetchedItem?.additionalSellTypes,
            defaultSellType: fetchedItem?.defaultSellType,
            price: fetchedItem?.price,
            oldPrice: fetchedItem?.oldPrice,
            campaignItems: fetchedItem.campaignItems,
            discountPrice: hasDiscount ? totalDiscount : undefined,
            campaignDiscount,
          });
        }
      });
      if (Ids.length !== updatedCart.length || hasChanges) {
        message.info("חל שינוי על המוצרים בסל");
      }
      const cart: { items: Product[]; total: number } = {
        items: updatedCart,
        total: 0,
      };
      dispatch(cartActions?.fetchCartV3({ cart }));
    }
  };
  useEffect(() => {
    fetchCartItems();
  }, [isVisible]);
  return {
    loading,
  };
};

export default useCartFetch;
