import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { PiInstagramLogoLight } from "react-icons/pi";
import { SAFlag } from "@/components/icons/SAFlag";
import { ILFlag } from "@/components/icons/ILFlag";
import { ROUTES } from "@/utils/routes";
import { FaSnapchat, FaTiktok, FaXTwitter } from "react-icons/fa6";

export const SocialMediaIcons = {
  instagram: PiInstagramLogoLight,
  facebook: FaFacebook,
  twitter: FaXTwitter,
  tiktok: FaTiktok,
  linkedIn: FaLinkedin,
  snapchat: FaSnapchat,
};

export const links = [
  // {
  //   id: 5000,
  //   text: "link-store",
  //   href: ROUTES.HOME,
  // },
  // {
  //   id: 5001,
  //   text: "link-about-us",
  //   href: ROUTES.ABOUT,
  // },

  {
    id: 5002,
    text: "link-customer-support",
    href: ROUTES.SUPPORT,
  },
  // {
  //   id: 5002,
  //   text: "link-faq",
  //   href: ROUTES.FAQ,
  // },
  {
    id: 5003,
    text: "link-shipping",
    href: ROUTES.SHIPMENTS,
  },
  {
    id: 5002,
    text: "link-terms",
    href: ROUTES.TERMS,
  },
];

export const languageMenu = [
  {
    id: "ar",
    name: "عربى - AR",
    value: "ar",
    icon: <SAFlag width="20px" height="15px" />,
  },

  {
    id: "he",
    name: "עברית - HE",
    value: "he",
    icon: <ILFlag width="20px" height="15px" />,
  },
];
export const privacyPolicy = [
  {
    id: "1",
    title: "privacy-one-title",
    description: "privacy-one-content",
  },
  {
    id: "2",
    title: "privacy-two-title",
    description: "privacy-two-content",
  },
  {
    id: "3",
    title: "privacy-three-title",
    description: "privacy-three-content",
  },
  {
    id: "4",
    title: "privacy-four-title",
    description: "privacy-four-content",
  },
  {
    id: "5",
    title: "privacy-five-title",
    description: "privacy-five-content",
  },
  {
    id: "6",
    title: "privacy-six-title",
    description: "privacy-six-content",
  },
  {
    id: "7",
    title: "privacy-seven-title",
    description: "privacy-seven-content",
  },
  {
    id: "8",
    title: "privacy-eight-title",
    description: "privacy-eight-content",
  },
];

export const categories = [
  {
    id: "21",
    name: "ירקות",
    subCategories: Array.from({ length: 5 }).map((_, index) => ({
      id: index + "21",
      name: "כל הירקות",
    })),
  },

  {
    id: "22",
    name: "ירקות",
    subCategories: Array.from({ length: 5 }).map((_, index) => ({
      id: index + "22",
      name: "כל הירקות",
    })),
  },
  {
    id: "23",
    name: "פירות",
    subCategories: Array.from({ length: 5 }).map((_, index) => ({
      id: index + "23",
      name: "כל הירקות",
    })),
  },
  {
    id: "24",
    name: "מזווה",
    subCategories: Array.from({ length: 2 }).map((_, index) => ({
      id: index + "24",
      name: "כל הירקות",
    })),
  },
  {
    id: "25",
    name: "רק היום",
    path: ROUTES.HOME,
  },
];

export const shipping_cities = [
  { id: "1", name: "אור יהודה" },
  { id: "2", name: "אזור" },
  { id: "3", name: "בית דגן" },
  { id: "4", name: "בני ברק" },
  { id: "5", name: "בת ים" },
  { id: "6", name: "גבעת שמואל" },
  { id: "7", name: "גבעתיים" },
  { id: "8", name: "גני תקווה" },
  { id: "9", name: "הרצליה" },
  { id: "10", name: "חולון" },
  { id: "11", name: "יהוד נווה מונסון" },
  { id: "12", name: "כפר שרמיהו" },
  { id: "13", name: "משמר השבעה" },
  { id: "14", name: "סביון" },
  { id: "15", name: "פתח תקווה" },
  { id: "16", name: "ראשון לציון" },
  { id: "17", name: "רמת גן" },
  { id: "18", name: "רמת השרון" },
  { id: "19", name: "תל אביב - יפו" },
];
