import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "./reducers"; // Adjust the import path as necessary

// Define the type for the root state of your store
export type RootState = ReturnType<typeof rootReducer>;

// Define the type for your Thunk action
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;
