import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Button, Input, InputRef, Modal } from "antd";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  handleSearch: (searchTerm: any) => void;
  lng?: string;
  loading: boolean;
  options: {
    value: any;
    label: React.JSX.Element;
  }[];
}

const SearchInputAutoComplete = React.forwardRef<HTMLInputElement, Props>(
  ({ className, handleSearch, loading, options, lng, ...rest }, ref) => {
    const autoFocusRef = useRef();
    const { t } = useTranslation("common");
    const [isVisible, setIsVisible] = useState(false);
    // const [searchTerm, setSearchTerm] = useState("");
    const onClose = () => {
      setIsVisible(false);
      handleSearch(undefined);
      // setSearchTerm("");
    };
    
    const onFocus = (open: boolean) => {
      setTimeout(() => {
        open && autoFocusRef?.current?.focus();
      }, 200);
    };

    return (
      <div className="relative">
        <Button
          type="text"
          onClick={() => setIsVisible(true)}
          icon={<IoSearchOutline size={23} />}
        />
        <Modal
          open={isVisible}
          onClose={onClose}
          onCancel={onClose}
          afterOpenChange={onFocus}
          destroyOnClose
          footer={null}
          // zIndex={999}
          title={t("placeholder-search")}
        >
          <div className="certain-category-search-wrapper relative">
            <Input
              ref={autoFocusRef}
              suffix={<IoSearchOutline size={24} />}
              className="border-primary rounded-full h-10 w-full"
              onInput={(e) => handleSearch(e.currentTarget.value)}
              // onInput={(e) => setSearchTerm(e.currentTarget.value)}
              // onKeyUp={() => handleSearch(searchTerm)}
              allowClear
              autoFocus
            />
            {isVisible && options?.length ? (
              <div className="absolute z-0 top-12 w-full left-0 bg-white rounded overflow-y-auto max-h-[12rem]">
                <OverlayScrollbarsComponent className="flex-grow w-full cart-scrollbar">
                  {options.map((item, index) => (
                    <div key={index}>{item.label}</div>
                  ))}
                </OverlayScrollbarsComponent>
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
);

SearchInputAutoComplete.displayName = "SearchInputAutoComplete";

export default SearchInputAutoComplete;
