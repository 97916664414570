"use client";
import React from "react";
import { links } from "@/data";
import Link from "next/link";
import useGlobalData from "@/hooks/useGlobalData";
import { useTranslation } from "@/app/i18n/client";

const Links = ({ lng }: { lng: string }) => {
  const { t } = useTranslation(lng, "footer");
  const { frontendSettings } = useGlobalData();
  const externalLinks = frontendSettings?.externalLinks || [];
  return (
    <div className="flex  gap-1 flex-col">
      {[...links, ...externalLinks]?.map((link, index) => (
        <Link
          href={link.isExternal ? link.href : `/${lng}${link?.href}`}
          className="flex items-center gap-4"
          key={`${index}-${link.id}`}
        >
          <span className="text-base text-paleYellowLight">{t(link?.text)}</span>
        </Link>
      ))}
    </div>
  );
};

export default Links;
