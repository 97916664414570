import { useState, useEffect } from "react";

export interface PaginationResponse<T> {
  data: {
    totalCount: number;
    list: T[];
  };
}

// Define the type for the fetchData parameters
interface FetchDataParams<T> {
  initial?: boolean;
  pageNumber: number;
  pageSize: number;
  customSearch?: Record<string, any>;
}

// Define the options type for the usePagination hook
interface UsePaginationOptions<T> {
  fetchOnMount?: boolean;
  paginationHandler: (
    params: Record<string, any>
  ) => Promise<PaginationResponse<T>>;
  pageSize?: number;
  key?: string;
  filters?: Record<string, any>;
  invokeRender?: any; // You can specify a more concrete type based on usage
}

const usePagination = <T,>({
  paginationHandler,
  pageSize = 20,
  key = "",
  filters = {},
  invokeRender,
  fetchOnMount = true,
}: UsePaginationOptions<T>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<T[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  useEffect(() => {
    if (fetchOnMount) {
      fetchData({ initial: true, pageNumber: 1, pageSize });
    }
  }, [invokeRender, fetchOnMount]);

  const fetchData = async ({
    initial = false,
    pageNumber,
    pageSize,
    customSearch = {},
  }: FetchDataParams<T>) => {
    setLoading(initial);
    const paginationParams = {
      pageNumber,
      pageSize,
      ...filters,
      ...customSearch,
    };
    const response = await paginationHandler(paginationParams);
    setCount(response?.data?.totalCount || 0);
    setLoading(false);
    if (initial) {
      setData(response?.data?.[key as keyof typeof response.data] as T[]);
      return;
    }
    setData((prev) => [
      ...prev,
      ...(response.data[key as keyof typeof response.data] as T[]),
    ]);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const newPage = page + 1;
    await fetchData({ pageNumber: newPage, pageSize });
    setPage((prevIndex) => prevIndex + 1);
    setLoadingMore(false);
  };

  const handleFilter = async (filters: Record<string, any>) => {
    setLoading(true);
    await fetchData({
      initial: true,
      pageNumber: page,
      pageSize,
      customSearch: filters,
    });
    setLoading(false);
  };

  return {
    loading,
    loadingMore,
    handleLoadMore,
    handleGetData: fetchData,
    data,
    count,
    page,
    setData,
    handleFilter,
  };
};

export default usePagination;
