import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\antd\\es\\index.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\react-toastify\\dist\\react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\react-toastify\\dist\\ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\drawer\\managed-drawer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\JoinEmail.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\layout\\ClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\layout\\footer\\links.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\layout\\footer\\social-media.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\layout\\header\\header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\modal\\managed-modal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["FacebookPixelEvents"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\pixel-events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\context\\StoreProvider.jsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\styles\\globals.css");
