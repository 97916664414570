export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  let initialized = typeof window !== "undefined" && !!window.fbq;
  if (initialized) window.fbq("track", "PageView");
};

export const event = (name, options = {}) => {
  if (typeof window !== "undefined") window.fbq("track", name, options);
};
