"use client";
import Link from "next/link";
import Image from "next/legacy/image";
import { motion } from "framer-motion";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import { IoClose } from "react-icons/io5";
import Counter from "../counter";
import useCart from "@/hooks/useCart";
import usePrice from "@/hooks/usePrice";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Button from "../ui/button";
import MeasurementsSelector from "../product/product-measurements-selector";
import useProductSellType from "../product/hooks/use-product-selltype";
import useUI from "@/hooks/useUI";
import { SellType } from "@/types";
import useDiscountPrice from "@/hooks/useDiscountProduct";
import useCampaignText from "@/hooks/useCampaignText";
import { images } from "@/constants/images";

const CartItem = ({
  item,
  onEditNoteClick,
}: {
  item: any;
  onEditNoteClick: (item: any) => void;
}) => {
  const {
    addItemToCart,
    removeItemFromCart,
    clearItemFromCart,
    updateItemSellType,
  } = useCart();
  const { setModalData, setModalView, openModal } = useUI();

  const { additionalSellTypes, defaultSellType, sellType } = item || {};

  const {
    step,
    quantityComparingToDefaultSellType,
    currentSellType,
    productSellTypes,
    handleSelectSellType,
  } = useProductSellType({ additionalSellTypes, defaultSellType, sellType });

  const { price } = usePrice({
    amount: item?.price * quantityComparingToDefaultSellType,
    currencyCode: "ILS",
  });
  const ItemTotalPrice =
    item?.quantity * item?.price * quantityComparingToDefaultSellType;
  const { price: totalPrice } = usePrice({
    amount: ItemTotalPrice,
    currencyCode: "ILS",
  });

  const handlePopupView = () => {
    setModalData({ data: { ...item, quantity: item.quantity } });
    setModalView("PRODUCT_VIEW");
    return openModal();
  };

  const onSelectSellType = (sellType: SellType) => {
    const newQuantity = handleSelectSellType(sellType, item.quantity);
    updateItemSellType(item.id, sellType, newQuantity);
    // setQuantity(newQuantity);
  };
  const onRemoveItem = (e: any) => {
    e.stopPropagation();
    clearItemFromCart(item.id, currentSellType);
  };
  const { getRepresentableTexts } = useCampaignText(item);
  const {
    discountPrice: { hasDiscount, discountPrice },
  } = useDiscountPrice(item, item.quantity, sellType);

  const totalDiscount = hasDiscount ? discountPrice : undefined;
  return (
    <motion.div
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.25)}
      className={`group w-full flex-col justify-start items-center bg-white py-3  border-b  border-primary relative  px-5 md:px-4 `}
      title={item?.title}
    >
      <div className="flex">
        <div
          className="relative flex flex-shrink-0 overflow-hidden  rounded-md cursor-pointer w-20 h-20 me-1"
          onClick={handlePopupView}
        >
          <Image
            src={item?.image || images.placeHolder}
            width={100}
            height={100}
            loading="eager"
            alt={item.name || "Product Image"}
            className="object-contain"
          />
          <div
            className="absolute top-0 flex items-start justify-start w-full h-full transition duration-200 ease-in-out"
            role="button"
          >
            <div
              className="bg-primaryLight rounded-full  transition duration-300 ease-in-out transform md:scale-0 md:opacity-0 md:group-hover:scale-100 md:group-hover:opacity-100"
              onClick={onRemoveItem}
            >
              <IoClose className="relative text-primary" size={19} />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <Link
            href="#"
            className="text-sm text-primary font-bold hover:text-primary"
          >
            {item?.title}
          </Link>
          <div className="flex items-center justify-between">
            <Button
              containerStyle="flex-none lg:w-[20%]"
              className="p-0"
              onClick={() => onEditNoteClick(item)}
              icon={
                <MdOutlineModeEditOutline className="text-primary" size={20} />
              }
            />
            <div className="flex items-center w-[60%]">
              <Counter
                quantity={item.quantity}
                onIncrement={() => addItemToCart(item, step)}
                onDecrement={() =>
                  removeItemFromCart(item.id, step, currentSellType)
                }
                variant="text"
                wrapperClassName="!w-fit"
              />
              <MeasurementsSelector
                size="xSmall"
                currentSelect={currentSellType}
                selectors={productSellTypes}
                handleSelect={onSelectSellType}
              />
            </div>
            <div className="flex flex-col items-end lg:w-[20%]">
              <span className="text-sm font-bold leading-3 text-primary">
                {totalPrice}
              </span>
              <span className="text-xs text-primary">
                {`${price}/${currentSellType?.text}`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          {getRepresentableTexts.map((text, index) => (
            <div className="flex flex-wrap gap-1 items-center" key={index}>
              <div className="bg-primaryLight rounded-full px-2 pb-[0.1rem] w-fit">
                <span className="text-xs text-white">{text.mainTitle}</span>
              </div>
              {text.secondaryTitle ? (
                <span className="text-xs block">*{text.secondaryTitle}</span>
              ) : null}
            </div>
          ))}
        </div>
        {totalDiscount && totalDiscount > 0 ? (
          <span dir="ltr" className="text-bold text-red-600">
            {`- ₪${totalDiscount.toFixed(2)}`}
          </span>
        ) : null}
      </div>
    </motion.div>
  );
};

export default CartItem;
