"use client";
import { Provider } from "react-redux";
import store from "@/lib/store";
import { GlobalDataProvider } from "./GlobalDataContext";

export const StoreProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <GlobalDataProvider>{children}</GlobalDataProvider>
    </Provider>
  );
};
