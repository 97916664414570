import { setCategories } from "@/lib/reducers/globalDataSlice";
import { getData, storeData } from "@/utils/storage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useFetchData from "./useFetchData";
import CategoriesActions from "@/actions/categories";
import useFetch from "./useFetch";

const CACHE_EXPIRY = 3600000; // 1 hour

const useCategoriesHook = () => {
  const { fetchData } = useFetch();

  const dispatch = useDispatch();
  const categoriesActions = CategoriesActions();
  const {
    data,
    loading,
  }: { data: { categoriesTree: any[] } | null; loading: boolean } =
    useFetchData({
      fetchData: () =>
        categoriesActions.getCategoriesTree({ categoryId: null }),
    });

  useEffect(() => {
    let isMounted = false;
    const handleGetCategories = async () => {
      const key = "categories";
      const currentTime = new Date().getTime();
      const response = await fetchData(async () =>
        categoriesActions.getCategoriesTree({ categoryId: null })
      );
      const categories = response.data?.categoriesTree;
      if (categories) {
        const newData = { data: categories, timestamp: currentTime };
        // storeData(key, newData);
        dispatch(setCategories(categories));
      }
    };
    if (!isMounted) {
      handleGetCategories();
    }
    return () => {
      isMounted = true;
    };
  }, []);

  return { data, loading };
};
export default useCategoriesHook;
