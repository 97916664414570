import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import projectsettings from "../../../appsettings.json";
import Image from "next/legacy/image";

const animationType = projectsettings.animations?.type;
const animationFile = projectsettings.animations?.file;

const EmptyCart = ({ width = 180, height = 309.722, className }) => {
  const [lottieData, setLottieData] = useState(null);

  useEffect(() => {
    if (animationType === "lottie") {
      fetch(animationFile)
        .then((response) => response.json())
        .then((data) => setLottieData(data))
        .catch((error) =>
          console.error("Error loading Lottie animation:", error)
        );
    }
  }, [animationFile]);
  if (animationType === "lottie" && lottieData) {
    return (
      <Lottie animationData={lottieData} loop={true} className="w-60 h-60" />
    );
  } else if (animationType === "image") {
    return (
      <div className="animate-bounce delay-150 ">
        <Image
          height={100}
          width={100}
          src={animationFile}
          className=" object-contain"
          alt="animation"
        />
      </div>
    );
  }
  return null;
};

export default EmptyCart;
