import apiCall from "@/network/apiCall";
import { CATEGORIES_URLS } from "@/network/api";
import { RequestMethod } from "@/utils/Enums";
import { GetTreeDto } from "@/types/dtos/categories";

const CategoriesRepository = () => {
  const getCategoriesTree = async (objectData: GetTreeDto) => {
    const data = await apiCall(
      CATEGORIES_URLS.GetTree,
      RequestMethod.POST,
      objectData
    );
    return data;
  };

  return { getCategoriesTree };
};

const categoriesRepository = CategoriesRepository();
export default categoriesRepository;
