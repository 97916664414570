import useCategoriesHook from "@/hooks/useCategories";
import usePreferencesHook from "@/hooks/usePreferences";
import { createContext, useContext, useEffect, useState } from "react";

// Create context
const GlobalDataContext = createContext({
  preferences: null,
  categories: null,
});

// Provider component
export const GlobalDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [preferences, setPreferences] = useState(null);
  const [categories, setCategories] = useState<any>(null);
  const { loading: preferencesLoading, data: preferencesData } =
    usePreferencesHook();
  const { loading: categoriesLoading, data: categoriesData } =
    useCategoriesHook();

  return (
    <GlobalDataContext.Provider value={{ preferences, categories }}>
      {children}
    </GlobalDataContext.Provider>
  );
};

// Custom hook to use the context
export const useGlobalData = () => useContext(GlobalDataContext);
