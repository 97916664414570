import useUI from "@/hooks/useUI";
import useCart from "@/hooks/useCart";
import { Badge, Button } from "antd";
import cn from "classnames";
import usePrice from "@/hooks/usePrice";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindconfig from "../../../tailwind.config";
import { PiBasket } from "react-icons/pi";
const CartButton = ({ className }) => {
  const { openCart } = useUI();
  const { cartTotalDistinctItems, totalPrices } = useCart();
  const handleCartOpen = () => openCart();
  const { total } = totalPrices || {};
  const { price } = usePrice({
    amount: total,
  });

  const fullConfig = resolveConfig(tailwindconfig);
  const primaryDark = fullConfig.theme.colors.primaryDark;

  return (
    <Button
      className="flex items-center gap-2 p-0 flex-shrink-0"
      onClick={handleCartOpen}
      type="text"
    >
      <Badge
        count={cartTotalDistinctItems}
        color={primaryDark.trim()}
        offset={[-18, 32]}
      >
        <div
          className={cn(
            "relative flex items-center justify-center flex-shrink-0 h-auto transform focus:outline-none",
            className
          )}
          aria-label="cart-button"
        >
          <PiBasket className="text-primaryDark" size={30} />
        </div>
      </Badge>
      <span className="font-bold text-cartText text-sm pt-1">{price}</span>
    </Button>
  );
};
export default CartButton;
