import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import GateWayActions from "@/actions/gateway";
import { setGlobalData } from "@/lib/reducers/globalDataSlice";
import useFetch from "./useFetch";
import { getData, storeData } from "@/utils/storage";
import CartActions from "@/actions/cart";

const CACHE_EXPIRY = 3600000; // Example: 1 hour in milliseconds
const theme = "vendor_market";
const usePreferencesHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const gateWayActions = GateWayActions();
  const cartActions = CartActions();
  const { fetchData } = useFetch();

  const changeFavicon = (url: string) => {
    const oldLink = document.querySelector("link[rel='icon']");
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    const newLink = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = url;
    document.head.appendChild(newLink);
  };

  useEffect(() => {
    let isMounted = false;
    const handleGetPreferences = async () => {
      const key = process.env.NEXT_PUBLIC_VENDOR_ID;
      const storedData = getData(key);
      const currentTime = new Date().getTime();
      if (
        storedData &&
        currentTime - (storedData?.timestamp || 0) < CACHE_EXPIRY
      ) {
        changeFavicon(storedData.data?.data?.vendorData?.favicon);
        dispatch(setGlobalData(storedData.data?.data));
      } else {
        const response = await fetchData(async () =>
          gateWayActions.getPreferences()
        );
        if (response) {
          const newData = { data: response, timestamp: currentTime };
          changeFavicon(response?.data?.vendorData?.favicon);

          storeData(key, newData);
          dispatch(setGlobalData(response?.data));
        }
      }
      setLoading(false);
    };
    if (!isMounted) {
      handleGetPreferences();
      const cart_v3 = getData("cart_v3");
      if (cart_v3) {
        // console.log("usePreferencesHook", cart_v3);
        dispatch(cartActions?.fetchCartV3(cart_v3));
      }
    }
    return () => {
      isMounted = true;
    };
  }, []);

  return { loading, data, theme };
};
export default usePreferencesHook;
