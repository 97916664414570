import apiCall from "@/network/apiCall";
import { GATEWAY_URLS } from "@/network/api";
import { RequestMethod } from "@/utils/Enums";
import { GetPreferencesDto } from "@/types";
const GateWayRepository = () => {
  const getPreferences = async (dataObj: GetPreferencesDto) => {
    const data = await apiCall(
      GATEWAY_URLS.GetPreferences,
      RequestMethod.POST,
      dataObj
    );
    return data;
  };

  const getCities = async (
    token: string | null,
    isIncludeOnlySupported?: boolean
  ) => {
    const data = await apiCall(
      `${GATEWAY_URLS.GetCities}?isIncludeOnlySupported=${
        isIncludeOnlySupported || false
      }`,
      RequestMethod.GET,
      null,
      "application/json",
      null,
      token
    );
    return data;
  };

  const getCityStreets = async (cityId: number) => {
    const data = await apiCall(
      `${GATEWAY_URLS.GetCityStreets}?cityId=${cityId}`,
      RequestMethod.GET
    );
    return data;
  };

  const checkCityAvailability = async (cityId: number) => {
    const data = await apiCall(
      GATEWAY_URLS.CheckCityAvailability,
      RequestMethod.POST,
      { cityId }
    );
    return data;
  };
  const subscribeToNewletter = async (email: string) => {
    const data = await apiCall(
      GATEWAY_URLS.SubscribeToNewletter,
      RequestMethod.POST,
      { email }
    );
    return data;
  };
  return {
    getPreferences,
    getCities,
    checkCityAvailability,
    subscribeToNewletter,
    getCityStreets,
  };
};

const gateWayRepository = GateWayRepository();
export default gateWayRepository;
