"use client";

import { useTranslation } from "@/app/i18n/client";
import { Product } from "@/types/dtos/products";
import { Modal, Input, Button } from "antd";
import { useParams } from "next/navigation";
import Image from "next/legacy/image";
import { useState } from "react";
const { TextArea } = Input;
type ModalProps = {
  item: Product;
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSaveNote: (note?: string) => void;
  onRemoveNote: () => void;
};

const NoteModal: React.FC<ModalProps> = ({
  open,
  onClose,
  onCancel,
  item,
  onSaveNote,
  onRemoveNote,
}) => {
  const { lng } = useParams();
  const { t } = useTranslation(lng, "common");
  const [note, setNote] = useState<string | undefined>(item?.note);
  return (
    <Modal
      open={open}
      onClose={onClose}
      onCancel={onCancel}
      footer={null}
      centered={true}
      styles={{
        content: { padding: 0 },
      }}
      width="full"
      style={{ minWidth: "27vw" }}
    >
      <div className="w-full mx-auto overflow-hidden bg-white  rounded-lg px-6 py-5 flex items-center gap-10">
        <Image
          //@ts-ignore
          src={item?.mainImg}
          width={150}
          height={150}
          className="object-cover"
          alt="note image"
        />
        <div className="flex flex-col gap-4 w-full">
          <div>
            <h3 className="font-bold text-lg">{t("text-write-note")}</h3>
            <span>{item?.title}</span>
          </div>
          <TextArea
            color="primary"
            maxLength={70}
            value={note}
            placeholder={t("common:note-placeholder")}
            className="bg-transparent border-primary rounded-xl py-3"
            style={{ height: 70, resize: "none" }}
            onChange={(e) => setNote(e.target.value)}
          />
          <Button
            type="primary"
            className="rounded-full py-5"
            onClick={() => onSaveNote(note)}
          >
            {t("text-save-note")}
          </Button>
          <Button type="text" onClick={() => onRemoveNote()}>
            <span className="underline">{t("text-delete-note")}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
