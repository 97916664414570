import useFetch from "@/hooks/useFetch";
import categoriesRepository from "@/repository/categories-repository";
import { GetTreeDto } from "@/types/dtos/categories";
const CategoriesActions = () => {
  const { handleApiResponse } = useFetch();
  const getCategoriesTree = (objectData: GetTreeDto) => {
    return handleApiResponse({
      showSuccessToast: false,
      callback: () => categoriesRepository.getCategoriesTree(objectData),
    });
  };

  return { getCategoriesTree };
};

export default CategoriesActions;
