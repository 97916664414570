"use client";
import React from "react";
import { Button, Form, Input } from "antd";
import { useParams } from "next/navigation";
import { useTranslation } from "@/app/i18n/client";
import Image from "next/legacy/image";
import useFetch from "@/hooks/useFetch";
import GateWayActions from "@/actions/gateway";
import Link from "next/link";
import useGlobalData from "@/hooks/useGlobalData";
const JoinEmail = () => {
  const [form] = Form.useForm();
  const { lng } = useParams();
  const { t } = useTranslation(lng, "common");
  const { fetchData, loading } = useFetch();
  const gateWayActions = GateWayActions();

  const onSubmit = async (values) => {
    const response = await fetchData(async () =>
      gateWayActions.subscribeToNewletter(values?.senderEmail)
    );

    if (response) {
      form.resetFields(["senderEmail"]);
    }
  };
  const { vendorData } = useGlobalData();
  const { iconImg } = vendorData || {};
  return (
    <div className="flex flex-col gap-3 text-bgLight">
      <div className="flex flex-col">
        <Link href={"/"}>
          {iconImg ? (
            <Image
              width={100}
              height={100}
              src={iconImg}
              alt="logo"
              className="size-32 object-contain"
            />
          ) : (
            <p>Loading image...</p>
          )}
        </Link>

        <h3
          className="font-bold text-bgLight text-xl"
          dangerouslySetInnerHTML={{
            __html: t("newsletter_join"),
          }}
        />
      </div>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        className="w-full mx-auto flex flex-col justify-center items-start  "
      >
        <Form.Item
          name="senderEmail"
          className="w-full text-bgLight"
          rules={[
            { required: true, message: t("common:label-email-required") },
            {
              pattern:
                /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t("common:email-error"),
            },
          ]}
        >
          <Input
            type="email"
            className=" placeholder-bgLight bg-transparent border-bgLight rounded-full py-3 hover:placeholder-black"
            placeholder={t("common:label-email-required")}
            disabled={loading}
          />
        </Form.Item>
        <div className="flex items-center justify-between w-full ">
          <Form.Item className="w-full flex  justify-center mb-0  ">
            <Button
              htmlType="submit"
              loading={loading}
              className="py-4 rounded-full px-7 bg-primaryLight hover:!bg-primaryLight  "
            >
              <span className="text-bgLight text-lg">
                {t("common:button-send-message2")}
              </span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default JoinEmail;
