import { AUTH_ACTION_TYPES } from "@/constants/actionTypes";
import { clearSession, getSession, saveSession } from "@/utils/session";

const {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_REQUEST,
  SEND_AGAIN_SUCCESS,
  VERIFY_OTP_SUCCESS,
  UPDATE_USER_SUCCESS,
  VERIFY_SUCCESS,
} = AUTH_ACTION_TYPES;

const initialState = {
  user: getSession()?.user,
  session: getSession(),
  isAuthorized: getSession()?.token ? true : false,
  isLoading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action?.payload,
        isLoading: false,
        error: null,
        isLoggedIn: true,
      };
    case VERIFY_SUCCESS:
      const { token, tokenExpiresAt, ...user } = action.payload?.data;

      const session = { user, token, expiresAt: tokenExpiresAt };
      saveSession(session);
      return {
        ...state,
        user,
        session,
        isAuthorized: true,
        isLoggedIn: true,
      };

    case UPDATE_USER_SUCCESS:
      const { user: _user } = action.payload;
      const newUser = { ...state.user, ..._user };
      const _session = { ...state.session, user: newUser };
      saveSession(_session);
      return {
        ...state,
        user: newUser,
        session: _session,
      };
    case SEND_AGAIN_SUCCESS:
      return {
        ...state,
        verificationId: action.payload,
        error: null,
        isLoggedIn: true,
      };
    case LOGOUT_SUCCESS:
      clearSession();
      return {
        ...initialState,
        user: null,
        session: null,
        isAuthorized: false,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
