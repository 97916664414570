"use client";
import React, { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { FB_PIXEL_ID } from "@/utils/fpixel";
import appSettings from "../../appsettings.json";

export const FacebookPixelEvents: React.FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { scripts } = appSettings;
  const { fb_pixels } = scripts || null;
  useEffect(() => {
    if (fb_pixels) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(FB_PIXEL_ID || "");
          ReactPixel.pageView();
        });
    }
  }, [pathname, searchParams]);

  return null;
};
