const { nextui } = require("@nextui-org/react");
const projectColors = require("./appsettings.json").colors;

type Colors = {
  [key: string]: string | Colors;
};
// Function to flatten nested color objects
function flattenColors(
  colors: Colors,
  parentKey: string = ""
): { [key: string]: string } {
  return Object.keys(colors).reduce<{ [key: string]: string }>((acc, key) => {
    const newKey = parentKey ? `${parentKey}-${key}` : key;
    const value = colors[key];

    if (typeof value === "object" && !Array.isArray(value)) {
      Object.assign(acc, flattenColors(value, newKey));
    } else {
      acc[newKey] = value as string;
    }
    return acc;
  }, {});
}

const flattenedColors = flattenColors(projectColors || {});

const additionalColors = {
  whatsappGreen: "#00ab00",
  secondary: "#D1DD7F",
  primaryTransparent: "#eef6f1",
  paleYellow: "#F7F3C4",
  primaryDarkLight: "#F0F9BE",
  primaryOrange: "#FEA847",
  body: "#5A5A5A",
  heading: "#1E3B27",
  input: "#1D1E1F",
  black: "#000",
  white: "#fff",
  linen: "#FBF1E9",
  linenSecondary: "#ECE7E3",
  olive: "#3D9970",
  maroon: "#B03060",
  brown: "#C7844B",
  placeholder: "#707070",
  borderBottom: "#f7f7f7",
  facebook: "#4267B2",
  facebookHover: "#395fad",
  google: "#4285F4",
  googleHover: "#307bf9",
  lightShade: "#FAFBFC",
  orange: {
    200: "#FEA847",
    300: "#FA911D",
  },
  gray: {
    50: "#FBFBFB",
    100: "#F1F1F1",
    150: "#F4F4F4",
    200: "#F9F9F9",
    300: "#E6E6E6",
    350: "#E9ECEF",
    400: "#999999",
    500: "#D8D8D8",
    600: "#3A3A3A",
    700: "#292929",
    800: "#707070",
    900: "#343D48",
  },
};

const finalColors = {
  ...additionalColors,
  ...flattenedColors,
};
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "./node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}",
  ],

  // darkMode: false,
  theme: {
    screens: {
      sm: "480px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1500px",
      "3xl": "1780px",
    },
    fontFamily: {
      openSans: ["Open Sans", "sans-serif"],
      heebo: ["Heebo", "sans-serif"],
      karantina: ["Karantina", "sans-serif"],
      rubik: ["Rubik", "sans-serif"],
    },
    extend: {
      colors: finalColors,
      fontSize: {
        "10px": ".625rem",
      },
      spacing: {
        "430px": "430px",
        "450px": "450px",
        "500px": "500px",
        "64vh": "64vh",
      },
      minHeight: {
        "50px": "50px",
      },
      scale: {
        80: "0.8",
        85: "0.85",
        300: "3",
        400: "4",
      },
      animation: {
        shine: "shine 1s",
        shineRTL: "shineRTL 1s",
        pulseBadge: "pulseBadge 1.5s infinite",
        fire: "fire 1s infinite ease-in-out",
        flicker: "flicker 1s infinite alternate",
      },
      keyframes: {
        shine: {
          "100%": { left: "125%" },
        },
        shineRTL: {
          "100%": { right: "125%" },
        },
        pulseBadge: {
          "0%, 100%": { transform: "scale(1)", opacity: "1" },
          "50%": { transform: "scale(1.2)", opacity: "0.8" },
        },
        fire: {
          "0%, 100%": { transform: "translateY(0)", opacity: "0.8" },
          "50%": { transform: "translateY(-10px)", opacity: "1" },
        },
        flicker: {
          "0%, 100%": { opacity: "1" },
          "50%": { opacity: "0.7" },
        },
      },
    },
    boxShadow: {
      cart: "0 3px 6px rgba(0,0,0,0.12)",
      product: "0 6px 12px rgba(0,0,0,.08)",
      listProduct: "0 2px 4px rgba(0,0,0,.08)",
      navigation: "0 3px 6px rgba(0, 0, 0, 0.16)",
      navigationReverse: "0 -3px 6px rgba(0, 0, 0, 0.16)",
      header: "0 2px 3px rgba(0, 0, 0, 0.08)",
      vendorCard: "1px 1px 4px rgba(0, 0, 0, 0.12)",
      vendorCardHover: "0 6px 18px rgba(0, 0, 0, 0.12)",
      subMenu: "1px 2px 3px rgba(0, 0, 0, 0.08)",
      bottomNavigation: "0 -2px 3px rgba(0, 0, 0, 0.06)",
      cookies: "0 -2px 3px rgba(0, 0, 0, 0.04)",
      avatar: "0px 15px 30px rgba(0, 0, 0, 0.16)",
    },
  },
  plugins: [
    require("@tailwindcss/forms")({
      strategy: "class",
    }),
    nextui(),
  ],
};
