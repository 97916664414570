import Image from "next/legacy/image";
import Link from "next/link";
import cn from "classnames";
import useGlobalData from "@/hooks/useGlobalData";

type LogoProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string;
  size?: number;
  url?: string;
};
const Logo: React.FC<LogoProps> = ({
  className,
  size = 95,
  url = null,
  ...props
}) => {
  const { vendorData } = useGlobalData();
  const { iconImg } = vendorData || {};
  return (
    <Link
      href="/"
      className={cn("inline-flex focus:outline-none", className)}
      {...props}
    >
      <Image
        src={url ?? iconImg}
        alt="logo"
        width={size}
        height={size}
        objectFit="contain"
        loading="eager"
      />
    </Link>
  );
};

export default Logo;
