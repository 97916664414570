import { Product } from "@/types/dtos/products";
import { useMemo } from "react";
import usePrice from "./usePrice";
import { GetItemDiscount } from "@/lib/calculations/discountCalculator";
import { SellType } from "@/types";

const useCampaignText = (product: Product) => {
  const getRepresentableTexts = useMemo(() => {
    const { campaignItems } = product || {};
    const texts = campaignItems?.map((campaign) => campaign.representableTexts);
    return texts || [];
  }, [product]);

  return { getRepresentableTexts };
};

export default useCampaignText;
export const useCampaignPrice = (
  product: Product,
  currentSellType: SellType,
  quantityComparingToDefaultSellType: number
) => {
  const { price, oldPrice, title } = product || {};
  const { itemDiscount, hasDiscount } = GetItemDiscount(
    product,
    currentSellType
  );
  const { price: priceWithoutDiscount, basePrice: basePriceWithoutDiscount } =
    usePrice({
      amount: Number(price) * quantityComparingToDefaultSellType,
      baseAmount: Number(oldPrice) * quantityComparingToDefaultSellType,
    });
  const { price: basePriceWithDiscount, basePrice: priceWithDiscount } =
    usePrice({
      amount: itemDiscount,
      baseAmount: Number(price),
    });

  return {
    price: hasDiscount ? priceWithDiscount : priceWithoutDiscount,
    basePrice: hasDiscount ? basePriceWithDiscount : basePriceWithoutDiscount,
  };
};
