import usePrice from "@/hooks/usePrice";
import { Product } from "@/types/dtos/products";
import React from "react";
import useProductSellType from "./hooks/use-product-selltype";
import EventsActions from "@/actions/events";
import useUI from "@/hooks/useUI";
import Image from "next/legacy/image";
import { RiDiscountPercentLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { images } from "@/constants/images";

const ProductSearch = ({ product }: { product: Product }) => {
  const { t } = useTranslation("common");
  const { price } = usePrice({
    amount: product.price,
  });
  const eventsActions = EventsActions();
  const { additionalSellTypes, defaultSellType, campaignItems } = product || {};
  const { setModalData, openModal, setModalView } = useUI();

  const { currentSellType } = useProductSellType({
    defaultSellType,
    additionalSellTypes,
  });
  const handlePopupView = () => {
    eventsActions.viewProduct(product?.id);
    setModalData({ data: { ...product } });
    setModalView("PRODUCT_VIEW");
    return openModal();
  };
  return (
    <div
      className="flex items-center justify-between gap-2 px-2"
      onClick={handlePopupView}
    >
      <div className="flex items-center gap-4 px-2">
        <Image
          src={product.mainImg || images.placeHolder}
          alt={product.title}
          width={40}
          height={40}
        />
        <div className="">
          <div>{product.title}</div>
          <div style={{ fontWeight: "bold", color: "#333" }}>
            {`${price}/${currentSellType?.text}`}
          </div>
        </div>
      </div>
      {campaignItems.length ? (
        <div className="flex items-center gap-1 bg-primaryLight  text-white rounded-full p-1">
          <span className="text-xs">{t("price_discount")}</span>
          <RiDiscountPercentLine size={15} />
        </div>
      ) : null}
    </div>
  );
};

export default ProductSearch;
