import React from "react";
import Link from "next/link";
import classNames from "classnames";
import ListMenu from "./ui/list-menu";
import { ROUTES } from "@/utils/routes";
import { RiPokerDiamondsFill } from "react-icons/ri";
const CategoriesMenu = ({ className, lng, categories }) => {
  return (
    <nav
      className={classNames(
        `headerMenu  relative items-center justify-center hidden lg:flex flex-wrap`,
        className
      )}
    >
      {categories?.map((item, index) => (
        <React.Fragment key={item?.id}>
          <div
            className={`menuItem group cursor-pointer focus:outline-none  ${
              item.subCategories ? "relative" : ""
            }`}
            key={item.id}
          >
            <Link
              // href={`/${lng}/${ROUTES.STORE}/${item?.id}/כל ה${item?.name}`}
              href={`/${lng}/${ROUTES.STORE}/${item?.id}/${item?.name}`}
              className="relative inline-flex items-center px-3 py-2 text-2xl font-bold  text-headerText xl:px-4 group-hover:text-gray-900"
            >
              {item?.name}
            </Link>
            {item?.subCategories && item?.subCategories?.length ? (
              <div className="absolute invisible bg-white  opacity-0 group-hover:visible subMenu shadow-subMenu ltr:left-0 rtl:right-0 group-hover:opacity-100 z-40">
                <ul className="py-5 text-sm text-body">
                  {item.subCategories.map((menu, index) => {
                    const dept = 1;
                    const menuName = `sidebar-menu-${dept}-${index}`;

                    return (
                      <ListMenu
                        dept={dept}
                        data={menu}
                        hasSubMenu={menu.subMenu}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                        lng={lng}
                      />
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
          {categories.length - 1 > index ? (
            <RiPokerDiamondsFill className="text-primaryLight" size={20} />
          ) : null}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default CategoriesMenu;
