import { useEffect, useState } from "react";

type UseFetchDataProps<T> = {
  fetchData: () => Promise<{ data: T }>;
  refetch?: boolean;
  enableFetch?: boolean;
};

type UseFetchDataResult<T> = {
  data: T | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
  loading: boolean;
};
function useFetchData<T>({
  fetchData,
  refetch,
  enableFetch = true,
}: UseFetchDataProps<T>): UseFetchDataResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!enableFetch) return;
    const fetchDataAsync = async () => {
      setLoading(true);
      const response = await fetchData();
      const { data } = response || {};
      //@ts-ignore
      setData(data || response);

      setLoading(false);
    };

    fetchDataAsync();
  }, [refetch]);

  return { data, loading, setData };
}

export default useFetchData;
