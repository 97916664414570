"use client";
import { useTranslation } from "@/app/i18n/client";
import Link from "next/link";
import cn from "classnames";
import { ROUTES } from "@/utils/routes";

const ListMenu = ({
  dept,
  data,
  hasSubMenu,
  hasMegaMenu,
  hasBrands,
  hasBanners,
  menuIndex,
  lng,
}) => {
  const { t } = useTranslation(lng, "menu");
  return (
    <li className={cn(!hasMegaMenu ? "group relative" : "")}>
      <Link
        href={
          `/${lng}/${ROUTES.STORE}/${data?.id}/${data?.name}` || ROUTES.HOME
        }
        className="flex items-center lg:text-xl py-2 ltr:pl-5 rtl:pr-5 ltr:xl:pl-7 rtl:xl:pr-7 ltr:pr-3 rtl:pl-3 ltr:xl:pr-3.5 rtl:xl:pl-3.5 hover:text-heading hover:bg-gray-300"
      >
        {t(data.name)}
      </Link>
      {hasSubMenu && (
        <SubMenu dept={dept} data={data.subMenu} menuIndex={menuIndex} />
      )}
    </li>
  );
};

const SubMenu = ({ dept, data, menuIndex }) => {
  dept = dept + 1;
  return (
    <ul className="absolute z-0 invisible w-56 py-3 bg-gray-200 opacity-0 subMenuChild shadow-subMenu ltr:right-full rtl:left-full ltr:2xl:right-auto rtl:2xl:left-auto ltr:2xl:left-full rtl:2xl:right-full top-4">
      {data?.map((menu, index) => {
        const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={menu.subMenu}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </ul>
  );
};

export default ListMenu;
