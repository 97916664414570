"use client";
import React from "react";
import { SocialMediaIcons } from "@/data";
import { Button } from "antd";
import useGlobalData from "@/hooks/useGlobalData";

const SocialMedia = () => {
  const { vendorData } = useGlobalData();
  const { socialMediaLinks } = vendorData || [];
  return (
    <div className="flex items-center gap-4">
      {socialMediaLinks?.map((social, index) => {
        const Icon = SocialMediaIcons[social.type];
        return (
          <Button
            type="link"
            style={{ fontSize: 38 }}
            target="_blank"
            href={social.link}
            key={index}
            icon={<Icon color={"#F0F9BE"} />}
          />
        );
      })}
    </div>
  );
};

export default SocialMedia;
