import Button from "@/components/ui/button";
import Link from "next/link";

export default function AuthMenu({
  isAuthorized,
  href,
  className,
  btnProps,
  children,
}) {
  return isAuthorized ? (
    <Link href={href} className={className}>
      {children}
    </Link>
  ) : (
    <Button {...btnProps} className={className} />
  );
}
