import useFetch from "@/hooks/useFetch";
import gateWayRepository from "@/repository/gateway-repository";
const GateWayActions = () => {
  const { handleApiResponse } = useFetch();

  const getPreferences = () => {
    return handleApiResponse({
      showSuccessToast: false,
      callback: () =>
        gateWayRepository.getPreferences({
          pushNotificationToken: undefined,
          location: undefined,
        }),
    });
  };
  const getCities = (isIncludeOnlySupported?: boolean) => {
    return handleApiResponse({
      showSuccessToast: false,
      callback: () => gateWayRepository.getCities(null, isIncludeOnlySupported),
    });
  };

  const getCityStreets = (cityId?: number) => {
    if (!cityId) return;
    return handleApiResponse({
      showSuccessToast: false,
      showErrorToast: false,
      callback: () => gateWayRepository.getCityStreets(cityId),
    });
  };

  const subscribeToNewletter = (email: string) => {
    return handleApiResponse({
      callback: () => gateWayRepository.subscribeToNewletter(email),
    });
  };
  const checkCityAvailability = (cityId: number) => {
    return handleApiResponse({
      showSuccessToast: false,
      callback: () => gateWayRepository.checkCityAvailability(cityId),
    });
  };

  return {
    subscribeToNewletter,
    checkCityAvailability,
    getCities,
    getCityStreets,
    getPreferences,
  };
};

export default GateWayActions;
