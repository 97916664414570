import { create } from "zustand";

export const useUI = create((set) => ({
  displayModal: false,
  displayCart: false,
  modalData: null,
  modalView: "LOGIN_VIEW",
  displaySidebar: false,
  openModal: () => set({ displayModal: true }),
  closeModal: () => set({ displayModal: false }),
  openCart: () => set({ displayCart: true }),
  closeCart: () => set({ displayCart: false }),
  setModalView: (modalView) => set({ modalView: modalView }),
  setModalData: (data) => set({ modalData: data }),
  openSidebar: () => set({ displaySidebar: true }),
  closeSidebar: () => set({ displaySidebar: false }),
}));

export default useUI;
