"use client";
import React, { useState, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter, usePathname, useParams } from "next/navigation";
import cn from "classnames";
import { ROUTES } from "@/utils/routes";
import styles from "@/styles";
import Logo from "@/components/ui/logo";
import AuthMenu from "./auth-menu";
import useUI from "@/hooks/useUI";
import { useTranslation } from "@/app/i18n/client";
import useSession from "@/hooks/useSession";
import useWindowScroll from "@/utils/hooks/use-window-scroll";
import { IoMenu, IoSearchOutline } from "react-icons/io5";
// import SearchInput from "@/components/inputs/search-input";
import CategoriesMenu from "@/components/categories-menu";
import { Drawer, Button, AutoComplete, Input } from "antd";
import MobileSideBarContent from "../mobile-navigation/MobileSideBarContent";
import useSearch from "@/hooks/useSearch";
import ProductsActions from "@/actions/products";
import usePagination from "@/hooks/usePagination";
import ProductSearch from "@/components/product/product-search";
import SearchInputAutoComplete from "@/components/inputs/search-input-auto-complete";
import useGlobalData from "@/hooks/useGlobalData";
import { FaUserLarge } from "react-icons/fa6";
import { GoSync } from "react-icons/go";
const CartButton = dynamic(() => import("../../cart/cart-button"), {
  ssr: false,
});

const mainItems = [
  {
    label: "shipping",
    path: ROUTES.SHIPMENTS,
  },
  {
    label: "customer-service",
    path: ROUTES.SUPPORT,
  },
];
const MainHeader = ({ lng, t, handleMobileMenu }) => {
  const productsActions = ProductsActions();
  const [options, setOptions] = useState([]);
  const { frontendSettings } = useGlobalData();
  const { data, loading, handleFilter } = usePagination({
    paginationHandler: productsActions?.getProducts,
    key: "products",
    pageSize: frontendSettings?.itemsSearchCount || 5,
    fetchOnMount: false,
  });

  const { onChangeSearch } = useSearch({
    handleSearch: handleFilter,
  });

  const handleSearch = (value) => {
    if (value) {
      onChangeSearch(value);
    }
  };
  useEffect(() => {
    if (!data) return;
    const formattedOptions = data.map((product) => {
      return {
        value: product.title,
        label: <ProductSearch product={product} />,
      };
    });
    setOptions(formattedOptions);
  }, [data]);
  return (
    <div className="flex flex-row items-center">
      <div className="flex items-center lg:hidden">
        <Button
          type="text"
          icon={<IoMenu size={20} onClick={handleMobileMenu} />}
        />
        <SearchInputAutoComplete
          handleSearch={handleSearch}
          loading={loading}
          options={options}
        />
      </div>

      <div className="flex items-center gap-4">
        <ul className={`${styles.flexCenter} list-none gap-3 hidden lg:flex`}>
          {mainItems?.map((navItem, index) => (
            <React.Fragment key={index}>
              <li className="flex items-center group cursor-pointer relative">
                <Link
                  href={`/${lng}${navItem?.path}`}
                  className="font-normal text-cartText text-base whitespace-nowrap"
                >
                  {t(navItem?.label)}
                </Link>
              </li>
              {index < mainItems.length - 1 && (
                <li className="flex items-center">
                  <span className="w-[5px] h-[5px] bg-cartText rounded-full"></span>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
        <AutoComplete
          onSearch={handleSearch}
          style={{ width: 300 }}
          className="text-black h-10 lg:flex hidden w-full "
          placeholder={t("placeholder-search")}
          options={options}
        >
          <Input
            suffix={<IoSearchOutline size={24} />}
            style={{ width: "300px" }}
            className="border-black rounded-full h-10"
          />
        </AutoComplete>
      </div>
    </div>
  );
};

const Header = () => {
  const { lng } = useParams();
  const router = useRouter();
  const { isScrolled } = useWindowScroll();
  const { openModal, setModalView, openSidebar, displaySidebar, closeSidebar } =
    useUI();
  const { t } = useTranslation(lng, "common");
  const { isAuthorized } = useSession();
  const siteHeaderRef = useRef();
  const pathName = usePathname();
  const { categories } = useGlobalData();

  const handleLogin = () => {
    setModalView("LOGIN_VIEW");
    return openModal();
  };
  function handleMobileMenu() {
    return openSidebar();
  }
  const handleNavigateRecovery = () => {
    if (!isAuthorized) {
      handleLogin();

      return;
    }
    router.push(`/${lng}${ROUTES.ACCOUNT_ORDERS}`);
  };

  if (pathName?.includes(ROUTES.CHECKOUT)) {
    return null;
  }

  return (
    <>
      <header
        id="siteHeader"
        ref={siteHeaderRef}
        className={cn(
          `flex w-full box-border items-center justify-between pt-4 px-5 lg:px-16 sticky top-0 z-50 innerSticky transition duration-200 ease-in-out bg-secondaryLight innerSticky  `,
          {
            "shadow-header bg-secondaryLight px-2": isScrolled,
          }
        )}
      >
        <div className="flex flex-col gap-2  w-full ">
          <div className="flex justify-between w-full">
            <MainHeader
              t={t}
              lng={lng}
              pathName={pathName}
              handleMobileMenu={handleMobileMenu}
            />
            <div className="flex-1 flex justify-center">
              <Logo className="w-28 lg:w-40 xl:w-52 h-auto" size={80} />
            </div>
            <div className="xl:flex lg:flex items-center gap-4">
              <div className="hidden xl:flex lg:flex items-center justify-end gap-4">
                <div className="-mt-0.5 flex-shrink-0">
                  <AuthMenu
                    isAuthorized={isAuthorized}
                    href={ROUTES.ACCOUNT}
                    className="text-sm font-semibold xl:text-base  text-white"
                    btnProps={{
                      variant: "text",
                      style: { padding: 0 },
                      className:
                        "text-sm xl:text-base text-heading font-semibold focus:outline-none p-0",
                      children: (
                        <div className="flex flex-row items-start gap-2">
                          <FaUserLarge className="text-primaryDark" size={20} />
                          <span className="text-cartText font-bold text-base  sm:flex hidden  lg:hidden xl:flex">
                            {t("text-sign-in")}
                          </span>
                        </div>
                      ),
                      onClick: handleLogin,
                    }}
                  >
                    <div className="flex items-center text-cartText gap-3">
                      <FaUserLarge className="text-primaryDark" size={20} />
                      <span className="text-cartText">{t("text-account")}</span>
                    </div>
                  </AuthMenu>
                </div>
                <Button
                  type="text"
                  onClick={handleNavigateRecovery}
                  className="p-0 flex-shrink-0"
                >
                  <div className="flex flex-row items-center gap-2 ">
                    <GoSync className="text-primaryDark" size={20} />
                    <span className="text-cartText font-bold text-base  whitespace-nowrap lg:hidden xl:flex">
                      {t("text-order-recovery")}
                    </span>
                  </div>
                </Button>
              </div>
              <div className="max-lg:mt-4">
                <CartButton />
              </div>
            </div>
          </div>
          <CategoriesMenu lng={lng} categories={categories} />
        </div>
      </header>

      <Drawer open={displaySidebar} onClose={closeSidebar}>
        <MobileSideBarContent categories={categories} />
        {/* <MobileMenu categories={categories} lng={lng} /> */}
      </Drawer>
    </>
  );
};

export default Header;
