"use client";

import useUI from "../../hooks/useUI";
import { Modal } from "antd";
import dynamic from "next/dynamic";
const LoginForm = dynamic(() => import("../auth/login-form"));
const SignUpForm = dynamic(() => import("../auth/sign-up-form"));
const VerifyAuth = dynamic(() => import("../auth/verify-auth"));
const ProductPopup = dynamic(() => import("../product/product-popup"));

const ManagedModal = ({ lng }) => {
  const { displayModal, closeModal, modalView } = useUI();
  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
      onCancel={closeModal}
      footer={null}
      centered={true}
      zIndex={1000}
      styles={{
        content: { padding: 0 },
      }}
      // width="full"
      style={{ minWidth: "27vw", width: "100%" }}
    >
      {modalView === "LOGIN_VIEW" && <LoginForm lng={lng} />}
      {modalView === "SIGN_UP_VIEW" && <SignUpForm lng={lng} />}
      {modalView === "PRODUCT_VIEW" && <ProductPopup lng={lng} />}
      {modalView === "OTP_VIEW" && <VerifyAuth lng={lng} />}
    </Modal>
  );
};

export default ManagedModal;
