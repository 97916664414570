import { Product } from "@/types/dtos/products";
import DiscountCalculator from "./discountCalculator";

export const CalculateTotal = (items: any) =>
  parseFloat(
    (
      items?.reduce(
        (total: number, item: Product) =>
          total +
          (item?.quantity || 1) *
            item?.price *
            (item.sellType?.quantityComparingToDefaultSellType || 1),
        0
      ) || 0
    )?.toFixed(3) || 0
  );
export const CalculateTotalDiscount = (items: any) => {
  const total = parseFloat(
    (
      items?.reduce((total: number, item: Product) => {
        const quantity = item?.quantity || 1;
        const price = item?.price || 0;
        const sellTypeMultiplier =
          item.sellType?.quantityComparingToDefaultSellType || 1;
        const itemPrice = quantity * price * sellTypeMultiplier;

        // Calculate discount dynamically
        const { totalDiscount, hasDiscount } = DiscountCalculator(
          item,
          quantity,
          item.sellType
        );
        // console.log({ discountPrice, itemPrice });
        // Calculate total price after discount
        // const itemTotal = hasDiscount ? totalDiscount : 0;
        const itemTotal = itemPrice - (hasDiscount ? totalDiscount : 0);

        return total + itemTotal;
      }, 0) || 0
    )?.toFixed(3) || 0
  );
  return total;
};
export const CalculateDiscounts = (items: any) =>
  parseFloat(
    (
      items?.reduce((total: number, item: Product) => {
        const quantity = item?.quantity || 1;
        const { totalDiscount, hasDiscount } = DiscountCalculator(
          item,
          quantity,
          item.sellType
        );
        return total + (hasDiscount ? totalDiscount : 0);
      }, 0) || 0
    )?.toFixed(3) || 0
  );
export const CalculateCouponDiscounts = (
  items: any = [],
  couponDiscount?: number
) => {
  let totalDiscount = 0;
  if (!couponDiscount || !items || !Array.isArray(items)) return totalDiscount;
  items.forEach((item: Product) => {
    const {
      campaignDiscount,
      campaignItems,
      quantity = 1,
      price,
      title,
      sellType,
    } = item;
    if (campaignDiscount && campaignItems?.length) {
      const { CPQ, maxDuplicates } = campaignDiscount;
      let PD = 0;
      if (maxDuplicates === null) PD = quantity % CPQ;
      else {
        PD = CPQ <= quantity ? quantity - CPQ * maxDuplicates : quantity % CPQ;
      }
      if (PD > 0) {
        totalDiscount += (couponDiscount / 100) * PD * price;
      }
    } else {
      const pricePerSellType =
        price * (sellType?.quantityComparingToDefaultSellType || 1);
      totalDiscount += (couponDiscount / 100) * pricePerSellType * quantity;
    }
    // console.log({ title, price, quantity, sellType, totalDiscount });
  });
  return Math.max(totalDiscount, 0);
};

export const CalculateItemsTotal = (items: any) => ({
  total: CalculateTotalDiscount(items),
  totalBeforeDiscount: CalculateTotal(items),
  totalDiscounts: CalculateDiscounts(items),
});
