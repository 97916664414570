"use client";
import Scrollbar from "../scrollbar";
import { motion } from "framer-motion";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import useUI from "@/hooks/useUI";
import { IoClose } from "react-icons/io5";
import CartItem from "./cart-item";
import EmptyCart from "./empty-cart";
import useCart from "@/hooks/useCart";
import usePrice from "@/hooks/usePrice";
import { ROUTES } from "@/utils/routes";
import { useTranslation } from "@/app/i18n/client";
import { Button, Spin } from "antd";
import CartButton from "./cart-button";
import { useState } from "react";
import NoteModal from "../modal/NoteModal";
import useCartFetch from "../../hooks/useCartFetch";

const Cart = ({ lng, isVisible }) => {
  const { closeCart } = useUI();
  const {
    items,
    totalPrices,
    isEmpty,
    cartTotalDistinctItems,
    clearCart,
    saveNote,
  } = useCart();
  const { total, totalBeforeDiscount } = totalPrices || {};
  const { loading } = useCartFetch(isVisible);
  const [currentItem, setCurrentItem] = useState(null);
  const { t } = useTranslation(lng, "common");
  const { price: cartTotalPrice, basePrice: cartBasePrice } = usePrice({
    amount: total,
    baseAmount: totalBeforeDiscount,
  });
  const openNoteModal = (item) => {
    setCurrentItem(item);
  };
  const handleSaveNote = (note) => {
    saveNote(currentItem.id, currentItem.sellType, note);
    setCurrentItem(null);
  };
  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="w-full flex justify-between items-center relative px-5  border-b border-gray-100 py-5 bg-secondaryLight">
        <div className="flex items-center gap-4">
          <Button
            className="rounded-full"
            onClick={closeCart}
            type="text"
            aria-label="close"
            icon={
              <IoClose size={30} className="mt-1 md:mt-0.5 text-cartText" />
            }
          />
          <div>
            <h2
              className="m-0 text-4xl font-semibold text-cartText"
              style={{ fontFamily: "Karantina" }}
            >
              {t("text-shopping-cart")}
            </h2>
            <div className="flex items-center text-cartText">
              <span>{`${t("text-total")} ${cartTotalDistinctItems} ${t(
                "text-cart-products"
              )}`}</span>
              <Button
                className="font-bold underline text-cartText"
                type="text"
                onClick={clearCart}
              >
                {t("text-clear-cart")}
              </Button>
            </div>
          </div>
        </div>
        <CartButton />
      </div>
      {!isEmpty ? (
        loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <Scrollbar className="flex-grow w-full cart-scrollbar">
            <div className="w-full">
              {items?.map((item) => (
                <CartItem
                  item={item}
                  key={item.id}
                  t={t}
                  onEditNoteClick={openNoteModal}
                />
              ))}
            </div>
          </Scrollbar>
        )
      ) : (
        <motion.div
          layout
          initial="from"
          animate="to"
          exit="from"
          variants={fadeInOut(0.25)}
          className="flex flex-col items-center justify-center px-5 pt-8 pb-5 md:px-7"
        >
          <EmptyCart />
          <h3 className="pt-8 text-lg font-bold text-black">
            {t("text-empty-cart")}
          </h3>
        </motion.div>
      )}

      <div
        className="flex flex-col gap-2 items-center  px-5 pb-5 md:px-7 md:pb-7 bg-secondaryLight py-5"
        onClick={closeCart}
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-col">
            <span className="w-full ltr:pr-5 rtl:pl-5 text-cartText ">
              {`${t("text-total2")}:`}
            </span>
            {cartBasePrice ? (
              <div className="flex flex-wrap items-center gap-1 text-cartText">
                <span className="text-cartText line-through font-normal">
                  {cartBasePrice}
                </span>
                <span className={`font-bold text-3xl`}>{cartTotalPrice}</span>
              </div>
            ) : (
              <span className={`font-bold text-3xl`}>{cartTotalPrice}</span>
            )}
          </div>
          <Button
            href={!isEmpty ? ROUTES.CHECKOUT : `/${lng}/${ROUTES.HOME}`}
            type="primary"
            className="text-bgLight font-bold w-64 px-5 p-1 rounded-full text-sm sm:text-base py-6 !bg-primaryDark "
          >
            <span></span>
            {t("text-proceed-to-checkout")}
          </Button>
        </div>
        <p className="text-cartText font-semibold text-sm">
          {t("text-cart-price-change")}
        </p>
      </div>
      <NoteModal
        key={currentItem}
        item={currentItem}
        open={currentItem}
        onCancel={() => setCurrentItem(null)}
        onClose={() => setCurrentItem(null)}
        onSaveNote={(note) => handleSaveNote(note)}
        onRemoveNote={() => handleSaveNote(null)}
      />
    </div>
  );
};
export default Cart;
