"use client";
import { useTranslation } from "@/app/i18n/client";
import Cart from "../cart/cart";
import useUI from "@/hooks/useUI";
import { Drawer } from "antd";

const ManagedDrawer = ({ lng }) => {
  const { displayCart, closeCart } = useUI();
  const { t } = useTranslation(lng, "common");
  return (
    <Drawer
      title={t("text-shopping-cart")}
      placement="left"
      width={500}
      style={{
        padding: 0,
      }}
      styles={{ body: { padding: 0 }, header: { display: "none" } }}
      onClose={closeCart}
      open={displayCart}
    >
      <Cart lng={lng} isVisible={displayCart} />
    </Drawer>
  );
};

export default ManagedDrawer;
